import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { generateCookieContentState } from './service';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button } from '@bluevalet/common-ui';
import CookieConsentModal from './modal/CookieConsentModal';

import './CookieConsent.scss';

export const MODAL_COOKIE_HASH = 'cookie-consent';
const CONTAINER_ID = 'cookie-consent';

function isModalCookieHashOpen(hash) {
  return hash === `#${MODAL_COOKIE_HASH}`;
}

export default function CookieConsent({ display, consent, setConsent }) {
  const [isModalOpen, setIsModalOpen] = useState(isModalCookieHashOpen());
  const [container, setContainer] = useState(null);
  const { t } = useTranslation('cookies');
  const location = useLocation();
  const history = useHistory();
  const containerClassName = classNames('CookieConsentContainer', {
    CookieConsentDisplay: display,
  });

  useEffect(() => {
    setIsModalOpen(isModalCookieHashOpen(location.hash));
  }, [location.hash]);

  useEffect(() => {
    if (!container) {
      const container = document.createElement('div');
      container.id = CONTAINER_ID;
      setContainer(container);

      document.body.appendChild(container);
    }
  }, [container]);

  const handleOpenModal = () => {
    history.push(`${location.pathname}#${MODAL_COOKIE_HASH}`);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    history.replace(location.pathname);
    setIsModalOpen(false);
  };

  const handleConsentValidateChoice = consent => {
    setConsent(generateCookieContentState(consent));
  };

  const handleConsentValidateAllChoice = () => {
    setConsent(
      generateCookieContentState({
        adStorage: true,
        adUserData: true,
        adPersonalization: true,
        analyticsStorage: true,
        functionalityStorage: false,
        personalizationStorage: false,
        securityStorage: false,
      }),
    );
  };

  const handleConsentValidateNoChoice = () => {
    setConsent(
      generateCookieContentState({
        adStorage: false,
        adUserData: false,
        adPersonalization: false,
        analyticsStorage: false,
        functionalityStorage: false,
        personalizationStorage: false,
        securityStorage: false,
      }),
    );
  };

  return (
    <>
      {container !== null
        ? ReactDOM.createPortal(
            <>
              <div className={containerClassName}>
                <div>
                  <div className="CookieConsentTitle">
                    <span>{t('cookies.popInTitle')}</span>
                  </div>
                  <p>{t('cookies.popInText')}</p>
                  <p>{t('cookies.popInHelp')}</p>
                </div>
                <div className="CookieConsentButtons">
                  <button type="button" className="personalizedCookieButton" onClick={handleOpenModal}>
                    <span>{t('cookies.personalizeLabel')}</span>
                  </button>
                  <div className="CookieConsentButtonsResponse">
                    <Button label={t('cookies.refuseLabel')} onClick={handleConsentValidateNoChoice} small />
                    <Button label={t('cookies.acceptLabel')} onClick={handleConsentValidateAllChoice} primary small />
                  </div>
                </div>
              </div>
              <CookieConsentModal
                isOpen={isModalOpen}
                validateChoice={handleConsentValidateChoice}
                onRequestClose={handleCloseModal}
                consent={consent}
              />
            </>,
            container,
          )
        : null}
    </>
  );
}
