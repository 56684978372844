import React from 'react';
import Seo from '../../shared/modules/seo/Seo';
import classNames from 'classnames';

import './Page.scss';

export default function Page({ title, desc, hasStyle = false, children }) {
  const pageClassName = classNames('', { PageContainer: hasStyle });

  return (
    <div className={pageClassName}>
      <Seo title={title} desc={desc} />
      {children}
    </div>
  );
}
