import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Page from '../../../layout/page/Page';

import './ErrorPage.scss';

export default function ErrorPage() {
  const { t } = useTranslation();

  return (
    <Page title={t('notFound.title')} hasStyle>
      <div className="ErrorPageContainer">
        <h1>{t('notFound.title')}</h1>
        <p>
          {' '}
          {t('notFound.content.0')} <Link to="/"> {t('notFound.content.1')}</Link>
        </p>
      </div>
    </Page>
  );
}
