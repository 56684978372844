import { useCallback, useContext, useEffect, useState } from 'react';
import { bookingActions } from './BookingContext';
import StorageService from '../../core/storage/service';
import moment from 'moment';
import { localSavingDeletionTime } from '../../config';

export function usePersistedContext(context, key, version) {
  const [loading, setLoading] = useState(false);

  const { state, dispatch } = useContext(context);

  useEffect(() => {
    setLoading(true);
    StorageService.getItem(key)
      .then(value => {
        setLoading(false);

        if (value?.value) {
          const deletionTimeStamp = moment(value.value.timestamp).add(localSavingDeletionTime, 'minutes');

          if (moment(new Date()).isAfter(deletionTimeStamp.toDate())) {
            StorageService.removeItem(key);
          } else {
            if (value.version === version) {
              dispatch({
                type: bookingActions.updateContextObject,
                payload: value.value,
              });
            }
          }
        }
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
      });
  }, [key, version, dispatch]);

  const handleSetState = useCallback(
    value => {
      value.timestamp = new Date();
      StorageService.setItem(key, { version: version, value }).then(() =>
        dispatch({
          type: bookingActions.updateContextObject,
          payload: value.value,
        }),
      );
    },
    [key, version, dispatch],
  );

  const handleDeleteState = useCallback(() => {
    StorageService.removeItem(key);
  }, [key]);

  return [loading, state, handleSetState, handleDeleteState, dispatch];
}
