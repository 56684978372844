import * as Sentry from '@sentry/react';
import { sentryDsn, sentryEnabled, sentryEnv } from './config';

if (sentryEnabled) {
  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnv,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [process.env.REACT_APP_BTS_BASE_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
