import { lazy } from 'react';

export function safeLazy(factory) {
  return lazy(() =>
    factory().catch(err => {
      window.location.reload();
      return err;
    }),
  );
}
