export const contentVersion = process.env.REACT_APP_CONTENT_VERSION;

export const currency = '€';

export const mainLanguage = 'fr';
export const availableLanguages = [
  /*'fr', 'en', 'es', 'pt', 'nl', 'it'*/
];

export const customerServiceEmail = 'info@bluetransfer.fr';

export const canonicalBaseUrl = 'https://www.bluetransfer.fr';
export const blueValetBaseUrl = 'https://www.bluevalet.fr';

export const contactUsLink = lang =>
  `https://bluetransfer.zendesk.com/hc/${lang}/articles/4467596105746-Comment-contacter-le-Service-clients-Blue-Transfer-`;
export const helpLink = lang => `https://bluetransfer.zendesk.com/hc/${lang}`;

export const stripeAccountFrKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY_FR;

export const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const sentryEnv = process.env.REACT_APP_SENTRY_ENV;

export const localSavingDeletionTime = 30; // in minutes

export const sitesLpData = [
  {
    siteName: 'bordeauxMerignac',
    url: '/parking-bordeaux-merignac',
    siteId: 1,
  },
  {
    siteName: 'lyonStExupery',
    url: '/parking-lyon-st-exupery',
    siteId: 2,
  },
  {
    siteName: 'marseilleProvence',
    url: '/parking-aeroport-marseille',
    siteId: 3,
  },
  {
    siteName: 'toulouseBlagnac',
    url: '/parking-toulouse-blagnac',
    siteId: 4,
  },
  {
    siteName: 'parisCdg',
    url: '/parking-paris-cdg',
    siteId: 5,
  },
  {
    siteName: 'niceCoteDAzur',
    url: '/parking-nice-cotedazur',
    siteId: 6,
  },
  {
    siteName: 'parisBeauvais',
    url: '/parking-paris-beauvais',
    siteId: 7,
  },
];
