import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useGtmPageView } from '../gtm/hooks';
import { useHistory } from 'react-router-dom';
import { canonicalBaseUrl } from '../../../config';

export default function Seo({ title, desc }) {
  const { i18n } = useTranslation();
  const { location } = useHistory();

  useGtmPageView();

  const path = location.pathname === '/' ? '' : location.pathname;
  const canonicalLink = canonicalBaseUrl + path;

  return (
    <Helmet prioritizeSeoTags htmlAttributes={{ lang: i18n.language }}>
      <title>{title}</title>
      {desc && <meta name="description" content={desc} />}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Helmet>
  );
}
