import { useEffect, useRef } from 'react';

export function useClickOutside(callback) {
  const ref = useRef({});

  useEffect(() => {
    const handleClickOutside = e => {
      const allRef = Object.values(ref.current);
      const isClickedOnRef = allRef?.find(el => el.contains(e.target));
      if (!isClickedOnRef) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);

  return [ref];
}
